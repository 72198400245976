<template>
	<header class="gnb"
		:class="this.$store.state.header.isOpen ? 'on' : ''"
	>
		<div class="c_inner">
			<div class="top_logo">
				<router-link to="/">
					<img class="ci" src="@/assets/image/common/logo.svg" alt="logo_ci" />
				</router-link>
			</div>
			<button @click="toggleGnb('toggle')"
				class="toggle_btn"
				:class="this.$store.state.header.isOpen ? 'on' : ''"
			>
				<div class="top"></div>
				<div class="mid"></div>
				<div class="bottom"></div>
			</button>
			<div class="gnb_menu">
				<li>
					<div :class="path=='CompanyIntro' ? 'on' : ''">
						<span
							@click="()=>{this.$router.push('/CompanyIntro/CEO')}"
						>회사소개</span>
					</div>
					<ul class="menu_sub">
						<li><router-link to="/CompanyIntro/CEO">인사말</router-link></li>
						<li><router-link to="/CompanyIntro/BusinessAreaIntro">사업분야</router-link></li>
						<li><router-link to="/CompanyIntro/CompanySummary">회사개요</router-link></li>
						<li><router-link to="/CompanyIntro/Map">오시는길</router-link></li>
					</ul>
				</li>
				<li>
					<div :class="path=='BusinessArea' ? 'on' : ''">
						<span
							@click="()=>{this.$router.push('/BusinessArea/NewDesign')}"
						>사업분야</span>
					</div>
					<ul class="menu_sub">
						<li><router-link to="/BusinessArea/NewDesign">신축구조설계</router-link></li>
						<li><router-link to="/BusinessArea/PerformanceDesign">성능기반설계</router-link></li>
						<li><router-link to="/BusinessArea/SeismicEvaluation">내진성능평가</router-link></li>
						<li><router-link to="/BusinessArea/SafetyDiagnosis">안전진단점검</router-link></li>
						<li><router-link to="/BusinessArea/OptimalDesign">최적공법설계</router-link></li>
					</ul>
				</li>
				<li>
					<div :class="path=='MethodIntro' ? 'on' : ''">
						<span
							@click="()=>{this.$router.push('/MethodIntro/CTMD')}"
						>공법소개</span>
					</div>
					<ul class="menu_sub">
						<li><router-link to="/MethodIntro/CTMD">C-TMD</router-link></li>
						<li><router-link to="/MethodIntro/LRBTMD">LRB-TMD</router-link></li>
						<li><router-link to="/MethodIntro/STMD">S-TMD</router-link></li>
						<li><router-link to="/MethodIntro/PTMD">P-TMD</router-link></li>
						<li><router-link to="/MethodIntro/BUS">BUS합성보</router-link></li>
					</ul>
				</li>
				<li>
					<div :class="path=='Portfolio' ? 'on' : ''">
						<span
							@click="()=>{this.$router.push('/Portfolio/NewDesign')}"
						>주요실적</span>
					</div>
					<ul class="menu_sub">
						<li><router-link to="/Portfolio/NewDesign?page=1">신축구조설계</router-link></li>
						<li><router-link to="/Portfolio/PerformanceDesign?page=1">성능기반설계</router-link></li>
						<li><router-link to="/Portfolio/SeismicEvaluation?page=1">내진성능평가</router-link></li>
						<li><router-link to="/Portfolio/SafetyDiagnosis?page=1">안전진단점검</router-link></li>
						<li><router-link to="/Portfolio/OptimalDesign?page=1">최적공법설계</router-link></li>
					</ul>
				</li>
				<li>
					<div :class="path=='TechArchive' ? 'on' : ''">
						<span
							@click="()=>{this.$router.push('/TechArchive/Catalog')}"
						>기술자료</span>
					</div>
					<ul class="menu_sub">
						<li><router-link to="/TechArchive/Catalog?page=1">카다록</router-link></li>
						<li><router-link to="/TechArchive/Specification?page=1">시방서</router-link></li>
						<li><router-link to="/TechArchive/Profile?page=1">지명원</router-link></li>
						<li><router-link to="/TechArchive/NewDesign?page=1">신축구조설계</router-link></li>
						<li><router-link to="/TechArchive/PerformanceDesign?page=1">성능기반설계</router-link></li>
						<li><router-link to="/TechArchive/SeismicEvaluation?page=1">내진성능평가</router-link></li>
						<li><router-link to="/TechArchive/SafetyDiagnosis?page=1">안전진단점검</router-link></li>
					</ul>
				</li>
				<li>
					<div :class="path=='Customer' ? 'on' : ''">
						<span
							@click="()=>{this.$router.push('/Customer/Notice?page=1')}"
						>고객지원</span>
					</div>
					<ul class="menu_sub">
						<li><router-link to="/Customer/Notice?page=1">공지사항</router-link></li>
						<li><router-link to="/Customer/OnlineConsulting">질의응답</router-link></li>
						<li><router-link to="/Customer/Employeement?page=1">채용정보</router-link></li>
					</ul>
				</li>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	computed: {
		path(){
			return this.checkSubPath(this.$route.path);
		}
	},
	mounted(){
		window.addEventListener('resize', this.headerResizer);
	},
	unmounted(){
		window.removeEventListener('resize', this.headerResizer);
	},
	methods: {
		headerResizer(){
			if(window.innerWidth > 1000){
				this.toggleGnb('remove');
			}
		},
		toggleGnb(action="toggle"){
			if(action == 'toggle'){
				this.$store.commit('header/toggle');
			} else {
				this.$store.commit('header/close');
			}
		},
		checkSubPath(path){
			// 회사소개
			if(path.includes('/CompanyIntro/')) return 'CompanyIntro';
			
			// 사업분야
			if(path.includes('/BusinessArea/')) return 'BusinessArea';

			// 공법소개
			if(path.includes('/MethodIntro/')) return 'MethodIntro';

			// 주요실적
			if(path.includes('/Portfolio/')) return 'Portfolio';

			// 기술자료
			if(path.includes('/TechArchive/')) return 'TechArchive';

			// 고객지원
			if(path.includes('/Customer/')) return 'Customer';
		}
	}
}
</script>

<style scoped src="@/assets/css/components/header.css">
</style>