import store from '../store'

export const fetchData = {
  methods: {
    // 해당 url로 데이터 요청 후 응답오면 콜백 실행
    fetchData(url, callback = () => {},
      options={ 
        params: {},
        loadingEl: null
      }
    ) {
      if(options.loadingEl){
        options.loadingEl.classList.add('c_loading');
      } else {
        store.commit('loading/startLoading'); // (화면 전체) 로딩 시작
      }
      this.axios.get(url, {
        params: options.params,
        headers: {
          "channel-code" : "vkS8U9d8YRpxKXqqY5aeLs6mUEjl3M",
        },
      })
      .then(res => {
        console.log(res.data);
        if(!res.data?.success) {
          store.commit('alertBundle/error', res.data?.message ?? '데이터를 받아오는것에 실패했습니다.');
          return;
        }
        callback(res.data);
      }).catch(error => {
        console.error(error);
      }).finally(()=>{
        if(options.loadingEl){
          options.loadingEl.classList.remove('c_loading');
          options.loadingEl = null;
        } else {
          store.commit('loading/finishLoading'); // (화면 전체) 로딩 완료
        }
      });
    },
  },
}