<template>
  <Popup />

  <div class="c_content_container home">
    <section class="c_section main_banner">
      <div class="banner_container"
        @touchstart="bannerTouchStart"
        @touchmove="bannerTouchMove"
        @touchend="bannerTouchEnd"
      >
        <div
          :class="activeBanner == 0 ? 'active' : ''"
          class="banner_slide"
          @click="()=>this.setBannerActive(0)"
        >
          <img src="@/assets/image/home/banner/banner03.jpg" alt="banner3" />
          <div class="textBox">
            <p>DCR & CH</p>
            <h1>Displacement and Vibration Control</h1>
            <h2>
              TMD를 이용하여 구조물에 발생될수 있는 변위 및<br />
              진동을 효과적으로 제어합니다.
            </h2>
            <button @click="()=>this.$router.push('/BusinessArea/OptimalDesign')">
              상세보기
            </button>
          </div>
        </div>
        <div
          :class="activeBanner == 1 ? 'active' : ''"
          class="banner_slide"
          @click="()=>this.setBannerActive(1)"
        >
          <img src="@/assets/image/home/banner/banner02.jpg" alt="banner2" />
          <div class="textBox">
            <p>DCR & CH</p>
            <h1>Performance-Based Design</h1>
            <h2>
              구조물의 목표성능을 정하고 비선형해석을 통한<br />
              목표성능을 만족하는 최적의 구조설계를 진행합니다.
            </h2>
            <button @click="()=>this.$router.push('/BusinessArea/PerformanceDesign')">
              상세보기
            </button>
          </div>
        </div>
        <div
          :class="activeBanner == 2 ? 'active' : ''"
          class="banner_slide"
          @click="()=>this.setBannerActive(2)"
        >
          <img src="@/assets/image/home/banner/banner01.jpg" alt="banner1" />
          <div class="textBox">
            <p>DCR & CH</p>
            <h1>Structure Design</h1>
            <h2>
              우리가 생각하는 모든 구조물을<br />
              안전하고 경제적으로 디자인 합니다.
            </h2>
            <button @click="()=>this.$router.push('/BusinessArea/NewDesign')">
              상세보기
            </button>
          </div>
        </div>
      </div>
      <div class="banner_controller">
        <div class="pagination">
          <span
            v-for="i in 3" :key="i"
            :class="this.activeBanner === (i-1) ? 'on' : ''"
            @click="()=>this.setBannerActive(i-1)" 
          ></span>
        </div>
      </div>
    </section>

    <section class="c_section display_slide businessArea">
      <div class="c_inner">
        <h1 class="c_section_title">주요 사업분야</h1>
        <swiper class="slide_container" v-bind="latestSlideOption" >
          <swiper-slide
            @click="()=>{this.$router.push('/BusinessArea/NewDesign')}"
          >
            <div class="img_holder">
              <img src="@/assets/image/home/contents01.jpg" alt="contents01" />
            </div>
            <div class="textBox">
              <h1 class="title">신축구조설계</h1>
              <p class="description">
                최고의 전문가들로 구성된 당사의 전문기술진이 
                국내외 초고층구조물 및 대공간구조물, 특수구조물을 포함하여 
                일반건축물에 대한 최적의 구조해석 및 설계프로세스를 진행하여 
                안전성 및 경제성, 유지관리성을 갖춘 최고의 구조설계 엔지니어링을 제공합니다.
              </p>
            </div>
          </swiper-slide>
          <swiper-slide
            @click="()=>{this.$router.push('/BusinessArea/PerformanceDesign')}"
          >
            <div class="img_holder">
              <img src="@/assets/image/home/contents02.jpg" alt="contents02" />
            </div>
            <div class="textBox">
              <h1 class="title">성능기반설계</h1>
              <p class="description">
                귀하가 제시하는 최적의 구조물 성능을 찾기위해 
                당사는 비탄성시간이력해석(Inelastic Time history Analysis)을 통해
                항복후 소성거동 및 응력집중부위를 검토하고 
                최적의 안전성과 경제성을 갖춘 최고의 구조물을 설계합니다.
              </p>
            </div>
          </swiper-slide>
          <swiper-slide
            @click="()=>{this.$router.push('/BusinessArea/SeismicEvaluation')}"
          >
            <div class="img_holder">
              <img src="@/assets/image/home/contents03.jpg" alt="contents03" />
            </div>
            <div class="textBox">
              <h1 class="title">내진성능평가</h1>
              <p class="description">
                다년간 축적된 내진성능평가 경험과 최고의 기술력을 바탕으로
                귀하의 구조물에 대한 정확한 내진성능을 평가해 드립니다.
                내진보강이 필요한 경우 안전성, 시공성, 경제성, 유지관리성을
                종합적으로 고려한 최적의 내진보강대안을 제공합니다.
              </p>
            </div>
          </swiper-slide>
          <swiper-slide
            @click="()=>{this.$router.push('/BusinessArea/SafetyDiagnosis')}"
          >
            <div class="img_holder">
              <img src="@/assets/image/home/contents04.jpg" alt="contents04" />
            </div>
            <div class="textBox">
              <h1 class="title">안전진단점검</h1>
              <p class="description">
                기존 구조물에 대한 현장조사 및 비파괴시험을 통해
                기존 구조물에 대한 성능 및 잔존가치를 평가하고
                안전성 및 내구성 등을 파악하여
                귀하의 구조물에 대한 정확한 성능을 진단해 드립니다. 
              </p>
            </div>
          </swiper-slide>
          <swiper-slide
            @click="()=>{this.$router.push('/BusinessArea/OptimalDesign')}"
          >
            <div class="img_holder">
              <img src="@/assets/image/home/contents05.jpg" alt="contents05" />
            </div>
            <div class="textBox">
              <h1 class="title">최적공법설계</h1>
              <p class="description">
                구조물의 동적거동을 파악하기 위해 동하중을 적용한 시간이력해석을 통해 
                구조물의 변위 및 진동을 알아내고 최신의 진동제어 기술인 
                제진장치(Damper Device)와 TMD(Tuned Mass Damper)를 적용해
                목표성능에 합당한 변위제어 설계를 제공합니다.
              </p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section class="c_section display_slide methodIntro">
      <div class="c_inner">
        <h1 class="c_section_title">공법소개</h1>
        <swiper class="slide_container" v-bind="latestSlideOption" >
          <swiper-slide
            @click="()=>{this.$router.push('/MethodIntro/LRBTMD')}"
          >
            <div class="img_holder">
              <img src="@/assets/image/home/method_lrbtmd.png" alt="method_lrbtmd" />
            </div>
            <div class="textBox">
              <h1 class="title">LRB-TMD</h1>
              <p class="description">
                TMD의 강성역할을 하는 스프링과 댐퍼역할을 하는 감쇠장치를 하나의 LRB(Lead Rubber Bearing)를 사용해서 TMD 제작을 간소화하여 시공성과 제작원가를 획기적으로 낮춘 TMD공법
              </p>
            </div>
          </swiper-slide>
          <swiper-slide
            @click="()=>{this.$router.push('/MethodIntro/CTMD')}"
          >
            <div class="img_holder">
              <img src="@/assets/image/home/method_ctmd.png" alt="method_ctmd" />
            </div>
            <div class="textBox">
              <h1 class="title">C-TMD</h1>
              <p class="description">
                X, Y 방향의 질량을 달리하여 2축 방향의 제진이 동시에 수행될 수 있는 동조질량감쇠 장치
              </p>
            </div>
          </swiper-slide>
          <swiper-slide
            @click="()=>{this.$router.push('/MethodIntro/STMD')}"
          >
            <div class="img_holder">
              <img src="@/assets/image/home/method_stmd.jpg" alt="method_stmd" />
            </div>
            <div class="textBox">
              <h1 class="title">S-TMD</h1>
              <p class="description">
                공장바닥, 보도교 및 장스팬 바닥 상시진동제어
              </p>
            </div>
          </swiper-slide>
          <swiper-slide
            @click="()=>{this.$router.push('/MethodIntro/PTMD')}"
          >
            <div class="img_holder">
              <img src="@/assets/image/home/method_ptmd.jpg" alt="method_ptmd" />
            </div>
            <div class="textBox">
              <h1 class="title">P-TMD</h1>
              <p class="description">
                산업설비의 배관 및 소방배관 진동제어
              </p>
            </div>
          </swiper-slide>
          <swiper-slide
            @click="()=>{this.$router.push('/MethodIntro/BUS')}"
          >
            <div class="img_holder">
              <img src="@/assets/image/home/method_bus.jpg" alt="method_bus" />
            </div>
            <div class="textBox">
              <h1 class="title">BUS합성보</h1>
              <p class="description">
                BUS(Bolted U-Section composite beam) 합성보 공법은 기존의 H형강을 이용하고 Z-section을 H형강과 볼트접합 하므로 시공성과 제작이 용이한 공법
              </p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section class="c_section notice">
      <div class="c_inner">
        <div class="notice_board">
          <h1 class="c_section_title">공지사항</h1>
          <ul class="notice_list">
            <li
              v-for="(item, i) in this.noticeList?.slice(0, 5)" :key="i"
              class="textBox"
              @click="()=>{this.$router.push(`/Customer/Notice/detail?list_pk=${item.list_pk}`)}"
            >
              <span>{{i+1}}</span>
              <h1>{{item.title}}</h1>
              <p class="date">{{this.dateFormat(item.enrollment_date)}}</p>
            </li>
            <li v-if="this.noticeList.length===0"
              class="default"
            >공지사항이 없습니다</li>
          </ul>
          <div class="button_wrapper">
            <button
              v-if="this.noticeList.length>5"
              class="c_button-primary more"
              @click="()=>{this.$router.push('/Customer/Notice?page=1')}"
            >더보기</button>
          </div>
        </div>

        <div class="contact">
          <h1 class="c_section_title">Contact</h1>
          <swiper @swiper="onSwiper" class="contact_slide" v-bind="contactSlideOption">
            <swiper-slide
              @click="()=>{this.$router.push('/Customer/OnlineConsulting')}"
            >
              <div>
                <img class="icon" src="@/assets/image/home/contact_icon01.png" alt="문의 아이콘">
                <h2>기술 및 견적 문의</h2>
                <p>
                  문의를 남겨주시면 담당자 확인 후<br>
                  빠른 시일내에 연락드립니다.
                </p>
              </div>
            </swiper-slide>
            <swiper-slide
              @click="()=>{this.$router.push('/CompanyIntro/Map')}"
            >
              <div>
                <img class="icon" src="@/assets/image/home/contact_icon02.png" alt="위치 아이콘">
                <h2>찾아오시는길</h2>
                <p>
                  서울시 동대문구 고미술로 81,<br>
                  평화빌딩 301호
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div>
                <img class="icon" src="@/assets/image/home/contact_icon03.png" alt="전화 아이콘">
                <h2>이메일 및 대표전화</h2>
                <div>
                  <p><a href="email:dcr@dcr-enc.com">e-mail: dcr@dcr-enc.com</a></p>
                  <p class="phone"><a href="tel:02-2138-0320">02.2138.0320</a></p>
                  <p class="fax">070.4032.0850</p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import "swiper/swiper-bundle.min.css"
SwiperCore.use([ Pagination, Autoplay, Navigation ]);

import { fetchData } from '@/utils/fetchData';
import { dateFormat } from '@/utils/dateFormat';

import Popup from '@/components/Popup';

export default {
  name: 'HomeView',
  components: {
    Swiper, SwiperSlide,
    Popup,
  },
  mixins: [ fetchData, dateFormat ],
  data(){
    return{
      activeBanner: 1,
      timeoutID: null,

      touchStartPosition: null,
      isBannerTouchMoving: false,

      dragStartPosition: null,
      isBannerDragging: false,

      noticeList: [],

      latestSlideOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          clickable : true,
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          599: {
            slidesPerView: 3,  //브라우저가 599보다 클 때
            spaceBetween: 25,
          },
          900: {
            slidesPerView: 4,  //브라우저가 900보다 클 때
            spaceBetween: 25,
          },
        },
      },
      contactSlideOption: {
        loop: true,
        cache: false,
        observer: true,
        observeParents: true,
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
          clickable : true,
          type: 'fraction',
        },
        navigation: true,
      },
      contactSwiper: null,
    }
  },
  mounted(){
    this.fetchNoticeList();
    setTimeout(() => {
      this.contactSwiper?.update();  // ctrl+shift+r 했을때 스와이퍼 깨지는거 방지
    }, 0);
  },
  methods: {
    onSwiper(swiper){
      this.contactSwiper = swiper;
    },
    // (main banner) touch event 시작
    bannerTouchStart(e){
      this.touchStartPosition = e.touches[0].clientX;
    },
    bannerTouchMove(e){
      if(this.isBannerTouchMoving) return;
      
      this.isBannerTouchMoving = true;
      if(e.touches[0].clientX < this.touchStartPosition){
        this.setBannerActive(this.activeBanner+1);
      } else if(e.touches[0].clientX >= this.touchStartPosition){
        this.setBannerActive(this.activeBanner-1);
      }
    },
    bannerTouchEnd(){
      this.isBannerTouchMoving = false;
      this.touchStartPosition = null;
    },
    // (main banner) touch event 끝

    fetchNoticeList(){  // 공지사항 fetch
      this.fetchData('/getList', (data) => {
        this.noticeList= data.list;
      }, {
        params : { 
          menu: 'Notice',
          division: '공지사항',
          type: '일반'
        },
        loadingEl: document.querySelector('.notice_board')
      });
    },
    openStartupDetail(){
      this.$router.push('/StartupInfoDetail')
    },
    setBannerActive(i){
      // 0.5초 동안 막기
      if(this.timeoutID != null) return;
      this.timeoutID = setTimeout(() => {
        this.timeoutID = null;
      }, 500);

      const max = 2;
      if(i < 0){
        this.activeBanner = max;
      } else if(i > max){
        this.activeBanner = 0;
      } else {
        this.activeBanner = i;
      }
    },
  },
}
</script>

<style scoped src="@/assets/css/views/home.css">
</style>
