import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";
import Home from '../views/Home.vue'

function setInitPage(to, next){
  // 진입 시 페이지 page 쿼리 없거나 page<=0이면 page=1 쿼리 페이지로 교체 (최대는 fetchBoardData안에 설정해놓음)
  if(isNaN(to.query.page) || to.query.page <= 0){
    next({ path: to.path, query: { page : 1 } })
  } else {
    next();
  }
}

const routes = [
  { // 홈
    path: '/',
    name: 'Home',
    component: Home
  },

  { // 회사소개 - CEO 인사말
    path: '/CompanyIntro/CEO',
    name: 'CEO',
    component: () => import(/* webpackChunkName: "CompanyIntro" */ '../views/CompanyIntro/CEO.vue')
  },
  { // 회사소개 - 사업분야
    path: '/CompanyIntro/BusinessAreaIntro',
    name: 'BusinessAreaIntro',
    component: () => import(/* webpackChunkName: "CompanyIntro" */ '../views/CompanyIntro/BusinessAreaIntro.vue')
  },
  { // 회사소개 - 회사개요
    path: '/CompanyIntro/CompanySummary',
    name: 'CompanySummary',
    component: () => import(/* webpackChunkName: "CompanyIntro" */ '../views/CompanyIntro/CompanySummary.vue')
  },
  { // 회사소개 - 오시는길
    path: '/CompanyIntro/Map',
    name: 'Map',
    component: () => import(/* webpackChunkName: "CompanyIntro" */ '../views/CompanyIntro/Map.vue')
  },

  { // 사업분야 - 신축설계
    path: '/BusinessArea/NewDesign',
    name: 'NewDesign',
    component: () => import(/* webpackChunkName: "BusinessArea" */ '../views/BusinessArea/NewDesign.vue')
  },
  { // 사업분야 - 성능설계
    path: '/BusinessArea/PerformanceDesign',
    name: 'PerformanceDesign',
    component: () => import(/* webpackChunkName: "BusinessArea" */ '../views/BusinessArea/PerformanceDesign.vue')
  },
  { // 사업분야 - 내진성능평가
    path: '/BusinessArea/SeismicEvaluation',
    name: 'SeismicEvaluation',
    component: () => import(/* webpackChunkName: "BusinessArea" */ '../views/BusinessArea/SeismicEvaluation.vue')
  },
  { // 사업분야 - 안전진단
    path: '/BusinessArea/SafetyDiagnosis',
    name: 'SafetyDiagnosis',
    component: () => import(/* webpackChunkName: "BusinessArea" */ '../views/BusinessArea/SafetyDiagnosis.vue')
  },
  { // 사업분야 - 공법적용
    path: '/BusinessArea/OptimalDesign',
    name: 'OptimalDesign',
    component: () => import(/* webpackChunkName: "BusinessArea" */ '../views/BusinessArea/OptimalDesign.vue')
  },
  
  { // 공법소개 - CTMD
    path: '/MethodIntro/CTMD',
    name: 'CTMD',
    component: () => import(/* webpackChunkName: "MethodIntro" */ '../views/MethodIntro/CTMD.vue')
  },
  { // 공법소개 - LRBTMD
    path: '/MethodIntro/LRBTMD',
    name: 'LRBTMD',
    // component: () => import(/* webpackChunkName: "MethodIntro" */ '../views/MethodIntro/LRBTMD.vue')
    component: () => import(/* webpackChunkName: "MethodIntro" */ '../views/Temp/Temp.vue')
  },
  { // 공법소개 - STMD
    path: '/MethodIntro/STMD',
    name: 'STMD',
    component: () => import(/* webpackChunkName: "MethodIntro" */ '../views/MethodIntro/STMD.vue')
  },
  { // 공법소개 - PTMD
    path: '/MethodIntro/PTMD',
    name: 'PTMD',
    component: () => import(/* webpackChunkName: "MethodIntro" */ '../views/MethodIntro/PTMD.vue')
  },
  { // 공법소개 - BUS합성보
    path: '/MethodIntro/BUS',
    name: 'BUS',
    // component: () => import(/* webpackChunkName: "MethodIntro" */ '../views/MethodIntro/BUS.vue')
    component: () => import(/* webpackChunkName: "MethodIntro" */ '../views/Temp/Temp.vue')
  },
  
  { // 주요실적 (Portfolio)
    path: '/Portfolio',
    name: 'Portfolio',
    component: () => import(/* webpackChunkName: "Portfolio" */ '../views/Portfolio/Portfolio.vue'),
    children: [
      {
        path: '',
        redirect: "/404"
      },
      {
        path: ':which',
        component: () => import(/* webpackChunkName: "TechArchive" */ '../components/ListBoard/ListBoard.vue'),
        props: route => ({ menu: 'Portfolio' , division: route.params.which }),
        beforeEnter: (to, from, next) => {
          const which = to.params.which;
          if(which!=='NewDesign' // 신축구조설계
          && which!=='PerformanceDesign'  // 성능기반설계
          && which!=='SeismicEvaluation'  // 내진성능평가
          && which!=='SafetyDiagnosis'  // 안전진단점검
          && which!=='OptimalDesign'){  // 최적공법설계
            next('/404')
          }
          setInitPage(to, next);
        },
      },
      {
        path: ':which/detail',
        component: () => import(/* webpackChunkName: "Portfolio" */ '../components/ListBoard/DetailBoard.vue'),
        props: true,
      },
    ],
  },

  { // 기술자료 (TechArchive)
    path: '/TechArchive',
    name: 'TechArchive',
    component: () => import(/* webpackChunkName: "TechArchive" */ '../views/TechArchive/TechArchive.vue'),
    children: [
      {
        path: '',
        redirect: "/404"
      },
      {
        path: ':which',
        component: () => import(/* webpackChunkName: "TechArchive" */ '../components/ListBoard/ListBoard.vue'),
        props: route => ({ menu: 'TechArchive' , division: route.params.which }),
        beforeEnter: (to, from, next) => {
          const which = to.params.which;
          if(which!=='Catalog'  // 카다록
          && which!=='Specification'  // 시방서
          && which!=='NewDesign'  // 신축구조설계
          && which!=='PerformanceDesign'  // 성능기반설계
          && which!=='SeismicEvaluation'  // 내진성능평가
          && which!=='SafetyDiagnosis'  // 안전진단점검
          && which!=='Profile'){  // 지명원
            next('/404')
          }
          setInitPage(to, next);
        },
      },
      {
        path: ':which/detail',
        component: () => import(/* webpackChunkName: "TechArchive" */ '../components/ListBoard/DetailBoard.vue'),
        props: true,
      },
    ],
  },

  { // 고객지원 (Customer)
    path: '/Customer',
    name: 'Customer',
    component: () => import(/* webpackChunkName: "Customer" */ '../views/Customer/Customer.vue'),
    children: [
      {
        path: '',
        redirect: "/404"
      },
      { // 질의응답
        path: 'OnlineConsulting',
        component: () => import(/* webpackChunkName: "Customer" */ '../components/CommonForm.vue'),
      },
      { // 공지사항
        path: 'Notice',
        component: () => import(/* webpackChunkName: "Customer" */ '../components/ListBoard/ListBoard.vue'),
        props: { menu: 'Notice' , division: 'Notice' },
        beforeEnter: (to, from, next) => {
          setInitPage(to, next);
        },
      },
      { // 공지사항 - 상세
        path: 'Notice/detail',
        component: () => import(/* webpackChunkName: "Customer" */ '../components/ListBoard/DetailBoard.vue'),
        props: { which: 'Notice' }
      },
      { // 채용정보
        path: 'Employeement',
        component: () => import(/* webpackChunkName: "Customer" */ '../components/ListBoard/ListBoard.vue'),
        props: { menu: 'Notice' , division: 'Employeement' },
        beforeEnter: (to, from, next) => {
          setInitPage(to, next);
        },
      },
      { // 채용정보 - 상세
        path: 'Employeement/detail',
        component: () => import(/* webpackChunkName: "Customer" */ '../components/ListBoard/DetailBoard.vue'),
        props: { which: 'Employeement' }
      },
    ],
  },
  
  {
    // Temp 페이지
    path: "/Temp",
    name: "Temp",
    component: () => import ( /* webpackChunkName: "Temp" */ "../views/Temp/Temp.vue"),
  },
  
  {
    // 404 페이지
    path: "/404",
    name: "404",
    component: () => import ( /* webpackChunkName: "404" */ "../views/404.vue"),
  },
  {
    // 존재하지 않는 페이지 404로 리다이렉트
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 맨 위로
  scrollBehavior (to, from, savedPosition) {
    // 기존 위치 존재하면 그 위치 반환
    if(savedPosition){
      return savedPosition;
    }

    // 쿼리만 다를 때는 스크롤 업 안함
    if(to.matched[0]?.path===from.matched[0]?.path && to.path!=='/') {
      return;
    }

    if(to.path===from.path || to.path==='/'){
      window.scrollTo({top : 0, behavior : 'smooth'});
      return;
    }

    window.scrollTo({top : 0})
  }
})

// 페이지 변경시 로딩 시작
router.beforeEach(() => {
  store.commit('loading/startDelayLoading'); 
  store.commit('header/close'); // 다른 페이지로 넘어갈 때 헤더 닫기(뒤로가기 때문)
})
// 페이지 변경시 로딩 끝냄
router.afterEach(() => {
  store.commit('loading/finishLoading');
})

export default router
