<template>
	<footer class="global_footer">
		<div class="c_inner">
			<div class="footer_info">
				<div class="info_detail">
					<ul>
						<li>㈜디씨알</li>
						<li>대표이사 : 김시범</li>
						<li>
							<p>본사: 경기도 김포시 김포한강5로 321, 김포한강듀클래스 6층 222호</p>
							<p>지사: 서울시 광진구 능동로 245, DH빌딩 401호</p>
						</li>
						<li>사업자 등록번호: 274-86-00449</li>
					</ul>
					<ul>
						<li>TEL: 02-2138-0320</li>
						<li>FAX: 070-4032-0850</li>
						<li>개인정보관리자: 김시범</li>
						<li>이메일 dcr@dcr-enc.com</li>
					</ul>
					<p class="copyright">&copy; {{ new Date().getFullYear() }} DCR ALL RIGHTS RESERVED.</p>
				</div>
				<!-- <nav class="social">
					<a href="https://search.naver.com/search.naver?sm=tab_hty.top&where=nexearch&query=%EC%95%84%EC%9D%B4%ED%94%BC%ED%85%8D%EC%BD%94%EB%A6%AC%EC%95%84&oquery=%EC%95%84%EC%9D%B4%ED%94%BC%ED%85%8D">
						<img src="@/assets/image/footer/naver.svg" alt="naver" />
					</a>
					<a href="https://twitter.com/IPTKorea">
						<img src="@/assets/image/footer/twitter.svg" alt="twitter" />
					</a>
					<a href="https://www.facebook.com/IPTKorea">
						<img src="@/assets/image/footer/facebook.svg" alt="facebook" />
					</a>
				</nav> -->
				<div class="customer">
					<div class="customer_center">
						<h2>고객센터</h2>
						<a href="tel:02-2138-0320">02-2138-0320</a>
					</div>
					<div class="customer_time">
						<p>평일 09:00-18:00</p>
						<p>점심 11:30-13:00</p>
						<p>(주말, 공휴일 휴무)</p>
					</div>
				</div>
			</div>
			<div class="ci">
				<router-link to="/">
					<img src="@/assets/image/footer/logo-gray.svg" alt="footer_logo" />
				</router-link>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: "Footer",
	};
</script>

<style scoped src="@/assets/css/components/footer.css"></style>
