<template>
  <!-- 모달팝업 START -->
	<div class="popup_container" v-if="this.popupOpen && this.popupContent.length > 0">
    <div class="c_screen_filter"></div>
		<div class="popup">
			<div class="popup_close">
				<label>
					<input type="checkbox" v-model="todayCheck" />
					<span>오늘 하루 보지않기</span>
				</label>
				<img src="@/assets/image/common/icon_close.svg" class="close_btn" @click="closePopup" />
			</div>
      <div v-if="this.popupContent.length == 1" class="popup_content">
        <div class="slide">
          <div class="img_wrapper" 
            :style="this.popupContent[0].url ? {cursor: 'pointer'} : {}"
            @click="openBlank(this.popupContent[0].url)"
          >
            <img :src="this.popupContent[0].path + this.popupContent[0].save_name" class="img_map" :alt="this.popupContent[0].original_name" />
          </div>
        </div>
      </div>
			<swiper v-else class="popup_content"
        v-bind="swiperOption"
      >
        <swiper-slide class="slide" v-for="(item, i) in this.popupContent" :key="i">
          <div class="img_wrapper"
            :style="item.url ? {cursor: 'pointer'} : {}"
            @click="openBlank(item.url)"
          >
            <img :src="item.path + item.save_name" class="img_map" :alt="item.original_name" />
          </div>
        </swiper-slide>
			</swiper>
		</div>
	</div>
	<!-- 모달팝업 END -->
</template>

<script>
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import "swiper/swiper-bundle.min.css"
SwiperCore.use([ Pagination,Autoplay ]);

import { fetchData } from '@/utils/fetchData';

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  mixins: [ fetchData ],
  mounted(){
    if(this.getCloseForToday()){
      this.popupOpen = false;
    }
    this.fetchPopData();
  },
  data(){
    return {
      popupOpen : true,
      todayCheck : false,
      popupContent : [],

      swiperOption : {
        loop:true,
        pagination : { clickable : true },
        autoplay:{
          delay : 3500, 
          disableOnInteraction : false 
        }
      }
    }
  },
  methods:{
    openBlank(url){
      if (url) {
        window.open(url);
      } else {
        return false;
      }
    },
    fetchPopData(){
      this.fetchData(`/getPopup`, (data)=>{
        this.popupContent = data.popup;
      })
    },
    closePopup(){
      this.popupOpen = false;

      if(this.todayCheck){
        this.setCloseForToday();
      }
    },
    setCloseForToday() {
        // 하루로 세팅
        const exdate = new Date();
        exdate.setDate(exdate.getDate() + 1);

        const cookie_value = escape(true) + '; expires=' + exdate.toUTCString();
        document.cookie = 'closeForToday' + '=' + cookie_value;
    },
    getCloseForToday() {
      let x, y;
      let val = document.cookie.split(';');

      for (let i = 0; i < val.length; i++) {
        x = val[i].substr(0, val[i].indexOf('='));
        y = val[i].substr(val[i].indexOf('=') + 1);
        x = x.replace(/^\s+|\s+$/g, ''); // 앞과 뒤의 공백 제거하기
        if (x == 'closeForToday') {
            return unescape(y); // unescape로 디코딩 후 값 리턴
        }
      }
    }
  }
}
</script>

<style scoped>
/* 모달 팝업 START */
.popup_container {
	z-index: 9999;
}
.popup_container .c_screen_filter {
	z-index: 9999;
	background: rgba(0, 0, 0, 0.7);
}
.popup_container .popup {
	z-index: 9999;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
  max-width: 500px;
	min-width: 300px;
}
.popup_container .popup .popup_close{
	padding: 1rem 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: var(--font-size-small);
	color: #fff
}
.popup_container .popup .popup_close label,
.popup_container .popup .popup_close label input,
.popup_container .popup .popup_close label span,
.popup_container .popup .popup_close button {
	display: inline-block;
	vertical-align: middle;
}
.popup_container .popup .popup_close label span{
	padding: 0 1rem;
}
.popup_container .popup .popup_close label input {
	margin: 0;
	width: 2rem;
	height: 2rem;
}
.popup_container .popup .popup_close .close_btn {
	width: 2rem;
	height: 2rem;
  margin-bottom: -0.5rem;
  cursor: pointer;
}
.popup_container .popup .popup_content {
  width: 100%;
}
.popup_container .popup .popup_content >>> .slide {
  height: 100%;
	padding-bottom: 3rem;
}
.popup_container .popup .popup_content >>> .slide .img_wrapper {
  height: 100%;
}
.popup_container .popup .popup_content >>> .slide .img_wrapper img {
	width: 100%;
  height: 100%;
  object-fit: contain;
}
.popup_container .popup .popup_content >>> .swiper-pagination {
	height: calc(4rem + 1px);
	bottom: 0;
}
.popup_container .popup .popup_content >>> .swiper-pagination-bullet {
	position: relative;
	top: 1rem;
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  opacity: 0.45;
}
.popup_container .popup .popup_content >>> .swiper-pagination-bullet-active{
  background-color: #fff;
  opacity: 1;
}
.popup_container .popup .popup_content >>> .slide .text_wrapper {
  padding: 4rem;
  font-size: var(--font-size-xx-small);
  line-height: 1.7;
  letter-spacing: 0;
  word-break: keep-all;
  user-select: none;
	box-sizing: border-box;
	border: none;
}
.popup_container .popup .popup_content >>> .slide .text_wrapper>* {
    vertical-align: middle;
}
.popup_container .popup .popup_content .text_wrapper .title {
    color: var(--primary-color);
    font-size: var(--font-size-x-large);
    font-weight: var(--font-w-bold);
    border-radius: 2rem;
    line-height: 4rem;
    margin-bottom: 2rem;
}
.popup_container .popup .popup_content .text_wrapper .title span {
    margin-right: 0.6rem;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    font-size: var(--font-size-xx-large);
    font-weight: var(--font-w-light);
    color: #fff;
    background: var(--primary-color);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}
.popup_container .popup .popup_content .text_wrapper .content {
    margin: 2rem 0;
    font-size: var(--font-size-mid);
    color: var(--font-color-default);
}
.popup_container .popup .popup_content .text_wrapper .content .sub_title {
    margin-bottom: 2rem;
    color: var(--primary-color);
    font-size: var(--font-size-x-large);
    font-weight: var(--font-w-bold);
    border-radius: 2rem;
    line-height: 3rem;
}

/* 모달 팝업 END */
</style>