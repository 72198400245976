export default {
  namespaced: true,
  state: {
    isOpen: false, // 헤더 펼침 여부
  },
  mutations: {
    open(state) { // 열기
      state.isOpen = true;
    },
    close(state) {  // 접기
      state.isOpen = false;
    },
    toggle(state){  // 토글
      state.isOpen = !state.isOpen;
    }
  }
}