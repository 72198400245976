<template>
  <AlertBundle />
  <Loading />
  <ToTop />
  
  <Header />

  <router-view
    :class="this.$store.state.header.isOpen ? 'c_no_overflow' : ''"
  />

  <Footer />
</template>

<script>
import AlertBundle from '@/components/AlertBundle';
import Loading from '@/components/Loading';
import ToTop from '@/components/ToTop';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
  components:{
    AlertBundle,
    Loading,
    ToTop,
    Footer, Header
  },
  mounted(){
    this.axios.post('https://jsonip.com/')  // clientIP 알아냄
    .then(res => {
      const clientIP = res.data.ip;
      this.axios.post('/saveVisitor', { // 방문자 저장
        ip: clientIP
      }, {
        headers: {
          "channel-code" : "vkS8U9d8YRpxKXqqY5aeLs6mUEjl3M",
        }
      })
    }).catch(error => {
      console.error(error, '방문자 저장에 실패했습니다');
    })
  }
}
</script>

<style>
@import url('./assets/css/common/reset.css');
@import url('./assets/css/common/common.css');
</style>
