<template>
  <transition name="fade">
    <div v-show="this.loading" id="loading"></div>
  </transition>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed:{
    ...mapState("loading", ['loading'] ),
  },
}
</script>

<style>
#loading {
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
#loading::after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 4rem);
  left: calc(50% - 2rem);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 0.7rem solid rgba(230,230,230, 0.7);
  border-top-color: var(--primary-color);
  animation: c_spining 0.7s ease-in-out infinite;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: all .2s ease-in-out;
}
</style>